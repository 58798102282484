module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#9E9E9E',

  primary: '#c62828',
  primaryLight: '#424242',
  primaryDark: '#b71c1c',

  secondary: '#757575',
  secondaryLight: '#f44336',
  secondaryDark: '#212121',
};
